import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loginform from './Loginform';
import Passwordform from './Passwordform';
import Passwordconfirm from './Passwordconfirm';

export const Login = ({userMail, userPassword, setUserMail, setUserPassword, usrOptions, btnLabel, setBtnLabel}) => {
  const [counter, setCounter] = useState(0);

  //Control user options
  const addOne = () => {
    setCounter(counter + 1);
    if (counter >= 4) {
      setCounter(0);
    }
    setBtnLabel(usrOptions[counter]);
  };
  const minusOne = () => {
    setCounter(counter - 1);
    if (counter <= 0) {
      setCounter(4);
    }
    setBtnLabel(usrOptions[counter]);
  };

  return (
    <div className="login-screen">
      <div className="login-background"/>
      <Switch>
        <Route exact path="/">
          <Loginform
            userMail={userMail}
            setUserMail={setUserMail}
            userPassword={userPassword}
            setUserPassword={setUserPassword}
            counter={counter}
            setCounter={setCounter}
            usrOptions={usrOptions}
            btnLabel={btnLabel}
            setBtnLabel={setBtnLabel}
            addOne={addOne}
            minusOne={minusOne}
          />
        </Route>
        <Route exact path="/recover/">
          <Passwordform
            userMail={userMail}
            setUserMail={setUserMail}
            btnLabel={btnLabel}
            addOne={addOne}
            minusOne={minusOne}
          />
        </Route>
        <Route exact path="/recover/send">
          <Passwordconfirm 
          userMail={userMail}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default Login;
