import React from 'react';
import Palomas from '../../Assets/palomas-menu.png'

const MenuContent = () => {
    return (
        <div className="menu-content">
            <img src={Palomas} alt="palomas"/>
        </div>
    )
}

export default MenuContent;
