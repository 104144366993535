import React, { useState } from 'react';
import Login from './Components/Login/Login';
import Menu from './Components/Menu/Menu';
import { BrowserRouter, Route } from 'react-router-dom';


function App() {
  const [userMail, setUserMail] = useState('');
  const [userPassword, setUserPassword] = useState('');

  const usrOptions = [
    'Administrador',
    'Coordinador',
    'Laboratorio',
    'Médico',
    'Institución/Sede'
  ];
  const [btnLabel, setBtnLabel] = useState(usrOptions[0]);

  return (
    <BrowserRouter>
      <Route exact path="/">
      <Login userMail={userMail}
        userPassword={userPassword}
        setUserMail={setUserMail}
        setUserPassword={setUserPassword}
        usrOptions={usrOptions}
        btnLabel={btnLabel}
        setBtnLabel={setBtnLabel} />
        </Route>

      <Route exact path="/menu">
      <Menu userMail={userMail}
        userPassword={userPassword}
        setUserMail={setUserMail}
        setUserPassword={setUserPassword}
        usrOptions={usrOptions}
        btnLabel={btnLabel}
        setBtnLabel={setBtnLabel} />
        </Route>
    </BrowserRouter>
  );
}

export default App;
