import React from 'react'

const LatMenu = () => {
    return (
        <div className="lat-menu">
            <ul className="lat-btns">
                <li>
                    <h5>Administrador</h5>
                </li>
                <button>Alta de usuarios</button>
                <button>Kits inventario</button>
                <button>Validar resultados</button>

                <li>
                    <h5>Laboratorio(s)</h5>
                </li>
                <button>Cargar resultados</button>
                <button>Autorización</button>

                <li>
                    <h5>Institución o sede</h5>
                </li>
                <button>Expediente</button>
                <button>Seguimiento</button>

                <li>
                    <h5>Médico</h5>
                </li>
                <button>Expediente</button>
                <button>Seguimiento</button>

                <li>
                    <h5>Reportes</h5>
                </li>
                <button>...</button>
                <button>...</button>
            </ul>
        </div>
    )
}

export default LatMenu
