import React from 'react';
import LatMenu from './LatMenu';
import MenuContent from './MenuContent';
import NavMenu from './NavMenu';

const Menu = ({ userMail, btnLabel }) => {
    return (
        <div className="main-menu">
            <NavMenu userMail={userMail}
                btnLabel={btnLabel}
            />
            <div className="grid-menu">

            <LatMenu />
            <MenuContent />
            </div>
        </div>
    )
}

export default Menu
