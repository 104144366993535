import React from 'react';
import { Link } from 'react-router-dom';
import logopharma from '../../Assets/login-logopharma.png';
import flecha from '../../Assets/login-flecha.png';

export const Loginform = ({userMail, setUserMail, userPassword, setUserPassword, btnLabel, addOne, minusOne}) => {
  

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if((!userMail) || (!userPassword)) {
      alert('Por favor revisa que tus datos estén completos')
    }
  };


  return (
    <div className="login-form">
      <img
        className="pharma-logo"
        alt="Pharma: Molecular medicine of Mexico"
        src={logopharma}
      />
      <div className="btn-column">
        <p>Selecciona tu perfil</p>
        <div className="btn-options">
          <button className="arrow" id="leftarr" onClick={minusOne}>
            <img alt="left arrow asset" className="arrowf i" src={flecha} />
          </button>
          <div className="selector">{btnLabel}</div>
          <button className="arrow" id="rightarr" onClick={addOne}>
            <img alt="right arrow asset" className="arrowf d" src={flecha} />
          </button>
        </div>
      </div>
      <form className="user-info" onSubmit={handleSubmit}>
        <div className="user-wrap">
          <div className="user-title">Usuario</div>
          <input
            className="input user"
            type="email"
            placeholder="Ingresa tu e-mail"
            onChange={(e) => setUserMail(e.target.value)}
            value={userMail}
            autoComplete="current-email"
            required="required"
          />
        </div>
        <div className="user-wrap">
          <div className="user-title">Contraseña</div>
          <input
            className="input password"
            type="password"
            placeholder="Ingresa tu contraseña"
            onChange={(e) => setUserPassword(e.target.value)}
            value={userPassword}
            autoComplete="current-password"
            required="required"
          />
        </div>
        <button className="check-info" type="submit">
          <Link to="/menu">
          Aceptar
          </Link>
        </button>
        <p>
          <Link to="/recover">¿Olvidaste la Contraseña?</Link>
        </p>
      </form>
    </div>
  );
};

export default Loginform;
