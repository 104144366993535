import React from 'react';
import logopharma from '../../Assets/login-logopharma.png';
import paloma from '../../Assets/login-paloma.png'

export const Passwordconfirm = ({userMail}) => {
  return (
    <div className="login-form">
      <img
        className="pharma-logo"
        alt="Pharma: Molecular medicine of Mexico"
        src={logopharma}
      />
      <img
        className="dove-login"
        alt="Dove logo, just for design purpose"
        src={paloma}
        />
      <p className="advertise">
        Tu solicitud fue recibida exitosamente.
        Tu nueva contraseña llegará en breve a <strong>{userMail}</strong>
      </p>
    </div>
  );
};

export default Passwordconfirm;
