import React from 'react';
import { Link } from 'react-router-dom';
import logopharma from '../../Assets/login-logopharma.png';
import flecha from '../../Assets/login-flecha.png';

export const Passwordform = ({ userMail, setUserMail, btnLabel, addOne, minusOne }) => {

  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="login-form">
      <img
        className="pharma-logo"
        alt="Pharma: Molecular medicine of Mexico"
        src={logopharma}
      />
      <p className="advertise">
        Si olvidaste tu Contraseña, solo ingresa tu email y selecciona tu Perfil
        para que el ADMIN, a la brevedad, te envíe una nueva.
      </p>
      <form className="user-info" onSubmit={handleSubmit}>
        <div className="user-wrap">
          <div className="user-title">Usuario</div>
          <input
            className="input user"
            type="email"
            placeholder="Ingresa tu e-mail"
            onChange={(e) => setUserMail(e.target.value)}
            value={userMail}
            autoComplete="current-email"
          />
        </div>
        <div className="btn-column">
          <p>Selecciona tu perfil</p>
          <div className="btn-options">
            <button className="arrow" id="leftarr" onClick={minusOne}>
              <img alt="left arrow asset" className="arrowf i" src={flecha} />
            </button>
            <div className="selector">{btnLabel}</div>
            <button className="arrow" id="rightarr" onClick={addOne}>
              <img alt="right arrow asset" className="arrowf d" src={flecha} />
            </button>
          </div>
        </div>
        <button className="check-info password" type="submit">
          <Link to="/recover/send">Enviar</Link>
        </button>
      </form>
    </div>
  );
};

export default Passwordform;
