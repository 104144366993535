import React from 'react';
import { Link } from 'react-router-dom';
import logopharma from '../../Assets/login-logopharma.png';
import ExitLogo from '../../Assets/icono-salir.svg';
import hamburger from '../../Assets/menu-ham.svg'

const NavMenu = ({ userMail, btnLabel }) => {

    const handleLogOut = () => {
        alert("Cerraste sesión");
    }

    return (
        <div className="navmenu">
            <img
                className="pharma-logo"
                alt="Pharma: Molecular medicine of Mexico"
                src={logopharma}
            />
            <img 
                className="menu-ham"
                alt="menu hamburger"
                src={hamburger} />
            <div className="profile-info">
                <p>USUARIO: {userMail}</p>
                <p>INSTITUCIÓN:</p>
            </div>
            <div className="session-info">
                <p>PERFIL: {btnLabel}</p>
                <button className="btn-session"><img src={ExitLogo} alt="Exit"></img></button>
                <button className="ext-btn-session" onClick={handleLogOut}><Link to="/">Cerrar sesión</Link></button>
            </div>
        </div>
    )
}

export default NavMenu
